const githubLink="https://github.com/killer-abhi";
const instaLink="https://instagram.com/killer._abhi";
const linkedInLink="https://www.linkedin.com/in/abhishek-kumar-1a295217b/";
const twitterLink="https://twitter.com/ABHIDK0914";
const emailLink="mailto:dk9554654249@gmail.com";

const profileLinks={
    githubLink,
    instaLink,
    linkedInLink,
    twitterLink,
    emailLink
}
const SocialData=profileLinks;
export default SocialData;

