const PersonalData = {
    firstName: "Milad",
    lastName: "Geravand",
    // nickName:"<  killer - abhi >",
    mobNo:"+98 9905702169",
    email:"miladgera014@gmail.com",
    address:"Iran",
    aboutMe:"Hi Everyone, I am Milad Geravand from Iran. I learned Javascript, Php, Python  etc, Worked with libraries & frameworks like ReactJS, NodeJS. Have a keen knowledge of databases like MySQL, MongoDB and Firebase. Eager to contribute to innovative projects and gain hands-on experience in a dynamic and collaborative",
    resumeLink:'',
    imageUrl:''
}
export const autoTypeData=['3rd Year IT Grad', 'MERN Stack Developer','Flutter App Developer', 'Open Source Contributor'];
export default PersonalData;
